import queryString from 'query-string';

import config from '../config';

const API_KEY = config.marvel.API_KEY;
const DOMAIN = config.marvel.ENDPOINT;

export const getURL = (endpoint, params) => {
  params.apikey = API_KEY;

  const stringified = queryString.stringify(params);
  return `${DOMAIN}${endpoint}?${stringified}`;
;}
