import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  card: {
    // minWidth: 300,
    textAlign: 'left',
    // flexGrow: 1,
    // margin: '10px',
  },
  media: {
    height: 0,
    // paddingTop: '56.25%', // 16:9
    paddingTop: '100%',
  },
  actions: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  link: {
    '&:hover': {
      textDecoration: 'none',
    }
  },
});

class CharacterCard extends React.Component {
  state = { expanded: false };

  getSubHeader = (str) => {
    const index = str.indexOf("(");
    if (index < 1) return null;
    return str.slice(index + 1, str.lastIndexOf(")"));
  }

  handleExpandClick = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  };

  render() {
    const { classes, character } = this.props;
    return (
      <Card className={classes.card}>
        <CardMedia
          className={classes.media}
          image={`${character.thumbnail.path}.${character.thumbnail.extension}`}
          title={character.name}
        />
        <CardHeader
          title={character.name.replace(/\(.*?\)/, '')}
          subheader={this.getSubHeader(character.name)}
        />
        <CardActions className={classes.actions} disableActionSpacing>
          {character.urls.map(value => (
            <Button size="small" color="primary" key={value.type}>
              <Link
                href={value.url}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.link}
              >
                {value.type}
              </Link>
            </Button>
          ))}
          {character.description
            ? <IconButton
                className={classnames(classes.expand, {
                  [classes.expandOpen]: this.state.expanded,
                })}
                onClick={this.handleExpandClick}
                aria-expanded={this.state.expanded}
                aria-label="Show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            : null
          }
        </CardActions>
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Typography paragraph>
              {character.description.replace(/<[^>]*>/g, '')}
            </Typography>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

CharacterCard.propTypes = {
  classes: PropTypes.object.isRequired,
  character: PropTypes.object.isRequired,
};

export default withStyles(styles)(CharacterCard);
