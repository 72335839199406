import React, { Component } from 'react';

import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { CSSTransition } from 'react-transition-group'

import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import Navigation from './navigation';
import Home from './home';
import NotFound from './home/NotFound';
// import PhotoGallery from './photos';
import Sudoku from './sudoku';
import SearchMarvel from './marvel';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#e62429' },
    secondary: { main: '#2979ff' },
  },
  typography: { useNextVariants: true },
});

const styles = theme => ({
  stryker: {
    textAlign: 'center',
  },
  strykerLogo: {
    height: '40vmin',
    filter: 'invert(1)',
    margin: '2vh',
  },
  strykerHeader: {
    backgroundColor: '#282c34',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(10px + 2vmin)',
    color: '#ffffff',
  },
});

const routes = [
  { path: '/', name: 'Home', Component: Home },
  // { path: '/photos', name: 'Photos', Component: PhotoGallery },
  { path: '/search', name: 'Search', Component: SearchMarvel },
  { path: '/sudoku', name: 'Sudoku', Component: Sudoku },
]

class App extends Component {

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider theme={theme}>
        <Router>
          <Switch className={classes.stryker}>
            {routes.map(({ path, Component }) => (
              <Route key={path} exact path={path}>
                {({ match }) => (
                  <CSSTransition
                    in={match != null}
                    timeout={300}
                    classNames="page"
                    unmountOnExit
                  >
                    <div className="page">
                      <Component />
                    </div>
                  </CSSTransition>
                )}
              </Route>
              ))}
              <Route component={NotFound} />
            </Switch>
            <footer className={classes.strykerFooter}>
              <Grid container direction="column" alignItems="center">
                <p>Created with <Link href="https://reactjs.org/">React.js</Link></p>
                <p>Copyright © Morgyn Stryker - 2019</p>
              </Grid>
            </footer>
            <Navigation />
          {/* </div> */}
        </Router>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(App);
