/*
 * Sudoku Game Generation
 *
 * Author: Morgyn Stryker
 * Date: March 2019
 * */

function getRandomIndex(items) {
  return Math.floor(Math.random()*items.length);
}

function getRandom(items) {
  return items[getRandomIndex(items)];
}

function swapRows(matrix, x, y) {
  const b = matrix[x];
  matrix[x] = matrix[y];
  matrix[y] = b;
  return matrix;
}

function transpose(matrix) {
  return Object.keys(matrix[0]).map(c => matrix.map(r => r[c]));
}

class SudokuBoard {
  constructor(complexity='easy') {
    this.complexity = complexity.toLocaleLowerCase();

    this.size = 9;
    this.solution = [];
    this.randomizeBoard();

    this.gameBoard = this.getPluckedBoard();
  }

  sqrt() {
    return Math.sqrt(this.size);
  }

  resetSolution() {
    // Create a starting matrix with non-repeating values
    this.solution = [];
    const sqrt = this.sqrt();
    // let currentRow = [1, 2, 3, 4, 5, 6, 7, 8, 9];
    let currentRow = Array.from({length: this.size}, (v, k) => k+1);

    for (let i = 0; i < this.size; i++) {
      let sliceValue = ( i !== 0 && i % sqrt === 0) ? sqrt + 1 : sqrt;
      if (i !== 0) {
        currentRow = currentRow.slice(sliceValue).concat(currentRow.slice(0,sliceValue));
      }
      this.solution.push(currentRow);
    }
  }

  randomizeBoard() {
    this.resetSolution();

    const groups = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
    ];
    let randomGroup, randomRow1, randomRow2;

    for (let i = 0; i < 25; i++) {
      randomGroup = getRandom(groups);
      randomRow1 = getRandom(randomGroup);
      randomRow2 = getRandom(randomGroup);
      if (randomRow1 !== randomRow2) {
        this.solution = swapRows(this.solution, randomRow1, randomRow2);
      }

      if (i%2 === 0) {
        this.solution = transpose(this.solution);
      }
    }
  }

  getSolutionCopy() {
    return this.solution.map(arr => arr.slice());
  }

  getPluckedBoard() {
    let startingBoard = this.getSolutionCopy();
    let removeNumber;
    switch (this.complexity) {
      case 'effortless':
        removeNumber = 15;
        break;
      case 'easy':
        removeNumber = 23;
        break;
      case 'medium':
        removeNumber = 30;
        break;
      default:
        removeNumber = 40;
        break;
    }

    let cells = [];
    for(let x = 0; x < 9; x++) {
      for(let y = 0; y < 9; y++) {
        let cell = {'x': x, 'y': y}
        cells.push(cell)
      }
    }

    let pickedCells = [];
    for(let i = 0; i < removeNumber; i++) {
      let randomIndex = getRandomIndex(cells);
      let pickedCell = cells.splice(randomIndex, 1)[0];
      pickedCells.push(pickedCell);
      startingBoard[pickedCell.x][pickedCell.y] = null;
    }

    Object.setPrototypeOf(startingBoard, new GameBoard());
    return startingBoard;
  }
}

class GameBoard extends Array {

  getCopy() {
    return this.map(arr => arr.slice());
  }

  getColumn(n) {
    return this.map(arr => arr[n]);
  }

  getRow(n) {
    return this[n];
  }

  getSquare(i, j) {
    let square = [];
    let startColumn, endColumn;
    let rowMatrix = this;
    if (i <= 2) {
      rowMatrix = this.slice(0, 3);
    } else if (i <= 5) {
      rowMatrix = this.slice(3, 6);
    } else {
      rowMatrix = this.slice(6);
    }

    if (j <= 2) {
      startColumn = 0;
      endColumn = 2;
    } else if (j <= 5) {
      startColumn = 3;
      endColumn = 5;
    } else {
      startColumn = 6;
      endColumn = 8;
    }

    for (let x = startColumn; x < endColumn; i++) {
      square.push = this.getColumn(rowMatrix, x);
    }
    return square;
  }

  getCount(arr, value) {
    return arr.filter(x => x === value).length;
  }

  isInRow(i, j, value) {
    // if the value is in the row
    const count = this.getCount(this[i], value);
    return count > 1;
  }

  isInColumn(i, j, value) {
    // if the value is in the column
    const count = this.getCount(this.getColumn(j), value);
    return count > 1;
  }

  isInSquare(i, j, value) {
    // if the value is in the squares
    let column;
    let startColumn, endColumn;
    let rowMatrix = this.getCopy();
    rowMatrix[i][j] = null;
    if (i <= 2) {
      rowMatrix = rowMatrix.slice(0, 3);
    } else if (i <= 5) {
      rowMatrix = rowMatrix.slice(3, 6);
    } else {
      rowMatrix = rowMatrix.slice(6);
    }

    if (j <= 2) {
      startColumn = 0;
      endColumn = 2;
    } else if (j <= 5) {
      startColumn = 3;
      endColumn = 5;
    } else {
      startColumn = 6;
      endColumn = 8;
    }

    for (let x = startColumn; x <= endColumn; x++) {
      column = rowMatrix.map(arr => arr[x]);
      let count = this.getCount(column, value);
      if (count > 1) return true;
    }
    return false;
  }

  hasConflict(i, j, value) {
    const number = Number(value);
    const inRow = this.isInRow(i, j, number);
    const inColumn = this.isInColumn(i, j, number);
    const inSquare = this.isInSquare(i, j, number);


    if (Number.isInteger(number) && (number < 1 || number > 9)) {
      return true;
    }
    return inRow || inColumn || inSquare;
  }

  isWinner() {
    for(let i = 0; i < this.length; i++) {
      for(let j = 0; j < this.length; j++) {
        if (this.hasConflict(i, j, this[i][j])) return false;
      }
    }
    return true;
  }

  isComplete() {
    // If there is a null value on the board, return false
    for(let i = 0; i < this.length; i++) {
      if (this[i].includes(null)) return false;
    }
    return this.isWinner();
  }
}

export default SudokuBoard;
