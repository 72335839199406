import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import CharacterCard from './CharacterCard';

const styles = theme => ({
  container: {
    padding: '2vh',
  },
});

class CharacterList extends React.Component {
  state = {
    checked: [1],
  };

  handleToggle = value => () => {
    const { checked } = this.state;
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    this.setState({
      checked: newChecked,
    });
  };

  render() {
    const { classes, items } = this.props;

    return (
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="stretch"
        spacing={16}
        className={classes.container}
      >
        {items.map(value => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2} key={value.id.toString()}>
            <CharacterCard character={value} />
          </Grid>
        ))}
      </Grid>
    );
  }
}

CharacterList.propTypes = {
  classes: PropTypes.object.isRequired,
  items: PropTypes.array,
};

export default withStyles(styles)(CharacterList);
