import React, { Component } from 'react';

import { withRouter } from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import Zoom from '@material-ui/core/Zoom';
import AppsIcon from '@material-ui/icons/Apps';
import HomeIcon from '@material-ui/icons/HomeSharp';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/SearchSharp';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  hamburger: {
    position: 'fixed',
    bottom: '0',
    right: '0',
    zIndex: 1000,
  },
  margin: {
    margin: '1vh',
  },
});

class AppNavigation extends Component {
  state = {
    expanded: false,
  };

  handleChange = () => {
    this.setState(state => ({ expanded: !state.expanded }));
  }

  handleClick = (location) => {
    const { history } = this.props;

    this.setState({
      expanded: false,
    })
    history.push(location)
  }

  render() {
    const { classes } = this.props;
    const { expanded } = this.state;

    return (
      <div className={classes.hamburger}>
        <Grid container direction="column">
          <Zoom in={expanded} style={{ transitionDelay: expanded ? '300ms' : '100ms'}}>
            <Tooltip title="Home" placement="left">
              <Fab
                aria-label="Home"
                className={classes.margin}
                color="secondary"
                onClick={() => this.handleClick("/")}
                size="medium"
              >
                <HomeIcon/>
              </Fab>
            </Tooltip>
          </Zoom>
          <Zoom in={expanded} style={{ transitionDelay: '200ms' }}>
            <Tooltip title="Search Marvel" placement="left">
              <Fab
                aria-label="Search Marvel"
                className={classes.margin}
                color="secondary"
                onClick={() => this.handleClick("/search")}
                size="medium"
              >
                <SearchIcon/>
              </Fab>
            </Tooltip>
          </Zoom>
          <Zoom in={expanded} style={{ transitionDelay: expanded ? '100ms' : '300ms' }}>
            <Tooltip title="Sudoku" placement="left">
              <Fab
                aria-label="Sudoku"
                className={classes.margin}
                color="secondary"
                onClick={() => this.handleClick("/sudoku")}
                size="medium"
              >
                <AppsIcon/>
              </Fab>
            </Tooltip>
          </Zoom>
          <Fab
            color="primary"
            aria-label="Navigation Menu"
            className={classes.margin}
            onClick={this.handleChange}
            size="medium"
          >
            <MenuIcon />
          </Fab>
        </Grid>
      </div>
    );
  }
}

export default withRouter(withStyles(styles)(AppNavigation));
