import React, { Component } from 'react';

import {
  withRouter,
} from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';

import logo from './logo.png';

const styles = theme => ({
  stryker: {
    textAlign: 'center',
  },
  strykerLogo: {
    height: '40vmin',
    filter: 'invert(1)',
    margin: '2vh',
  },
  strykerHeader: {
    backgroundColor: '#282c34',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(10px + 2vmin)',
    color: '#ffffff',
  }
});

class Home extends Component {

  render() {
    const { classes } = this.props;
    return (
      <header className={classes.strykerHeader}>
        <img src={logo} className={classes.strykerLogo} alt="logo" />
      </header>
    );
  }
}

export default withRouter(withStyles(styles)(Home));
