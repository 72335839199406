import React, { Component } from 'react';

import {
  withRouter,
} from "react-router-dom";

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
  stryker: {
    textAlign: 'center',
  },
  strykerLogo: {
    height: '40vmin',
    filter: 'invert(1)',
    margin: '2vh',
  },
  strykerHeader: {
    backgroundColor: '#282c34',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(10px + 2vmin)',
    color: '#ffffff',
  }
});

class NotFound extends Component {

  render() {
    const { classes } = this.props;
    return (
      <section className={classes.strykerHeader}>
        404: Not Found
      </section>
    );
  }
}

export default withRouter(withStyles(styles)(NotFound));
