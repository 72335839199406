import React, { Fragment } from 'react';
import classNames from 'classnames';

import { withRouter } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Input from '@material-ui/core/Input';
import ErrorIcon from '@material-ui/icons/SentimentVeryDissatisfiedSharp';
import WarningIcon from '@material-ui/icons/WarningSharp';
import Link from '@material-ui/core/Link';
import Grow from '@material-ui/core/Grow';
import SearchIcon from '@material-ui/icons/SearchSharp';
import Fab from '@material-ui/core/Fab';

import CharacterList from './CharacterList';
import { getURL } from './utils';

import MarvelLogo from './logo';

const buttonPadding = 16;

const styles = theme => ({
  container: {
    padding: '2vh 1vh',
  },
  inputMUI: {
    padding: '8px',
    background: '#ffffff',
  },
  marvelLink: {
    paddingLeft: '4px',
  },
  credits: {
    fontSize: '10px',
    fontStyle: 'italic',
    lineHeight: `${buttonPadding}px`,
    textAlign: 'right',
    paddingTop: '4px',
  },
  fabProgress: {
    position: 'absolute',
    top: -6,
    left: -6,
    zIndex: 1,
  },
  flexGrow: {
    flexGrow: 1,
  },
  marginLeftRight: {
    marginLeft: '1vh',
    marginRight: '1vh',
  },
  form: {
    width: '100%',
    maxWidth: '800px',
  },
  input: {
    border: 'none',
    display: 'block',
    flexGrow: 1,
    fontSize: `${buttonPadding}px`,
    padding: buttonPadding,
    outlineColor: '#ff0000',
  },
  loading: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonWrapper: {
    position: 'relative',
  },
});

class SearchMarvel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      input: '',
      items: [],
      response: {},
      loading: false,
      fetched: false,
    };
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.request(this.state.input);
  }

  request(value) {
    const url = getURL(this.props.endpoint, { nameStartsWith: value });

    this.setState({
      loading: true,
      fetched: false,
      error: null,
    })
    fetch(url)
      .then(res => res.json())
      .then(result => {
          this.setState({
            isLoaded: true,
            count: result.data.count,
            items: result.data.results,
            loading: false,
            fetched: true,
          });
        },
      )
      .catch(
        (error) => {
          this.setState({
            isLoaded: true,
            loading: false,
            fetched: true,
            error
          });
        }
      )
  }

  renderResults() {
    if (this.state.error != null) {
      return (
        <Grow in={true}>
          <div>
            <ErrorIcon style={{ fontSize: 100 }}/>
            <p>Failed to fetch data, please check your connection and try again.</p>
          </div>
        </Grow>
      )
    }

    if (this.state.items.length > 0) {
      return <CharacterList items={this.state.items} />
    } else if (this.state.items.length === 0 && this.state.fetched) {
      return (
        <Grow in={true}>
          <div>
            <WarningIcon style={{ fontSize: 100 }}/>
            <p>No results returned, please try a different search.</p>
          </div>
        </Grow>
      )
    }

    return null
  }

  render() {
    const { classes, className } = this.props;
    const disabled = this.state.input === '' || this.state.input == null;

    return (
      <Fragment>
        <form onSubmit={this.onSubmit} className={classes.form}>
          <Grid container justify="center" className={classNames(className, classes.container)}>
            <Grid container direction="row" justify="space-evenly">
              <Grid item className={classNames(classes.flexGrow, classes.marginLeftRight)}>
                <Grid container direction="column">
                  <Input
                    placeholder="Search Marvel"
                    className={classes.inputMUI}
                    inputProps={{
                      'aria-label': 'Search Marvel',
                    }}
                    onChange={this.handleChange('input')}
                    value={this.state.input}
                  />
                  <div className={classes.credits}>
                    Data provided by <Link href="https://marvel.com" className={classes.marvelLink}><MarvelLogo width="32.5" height="13" /></Link>
                  </div>
                </Grid>
              </Grid>
              <Grid item className={classNames(classes.buttonWrapper, classes.marginLeftRight)}>
                <Fab
                  aria-label="Search"
                  color="primary"
                  disabled={disabled}
                  onClick={this.onSubmit}
                  size="medium"
                  type="submit"
                >
                  <SearchIcon/>
                </Fab>
                {this.state.loading && <CircularProgress size={60} className={classes.fabProgress} color="secondary" />}
              </Grid>
            </Grid>
          </Grid>
        </form>
        {this.renderResults()}
      </Fragment>
    )
  }
};

SearchMarvel.defaultProps = {
  endpoint: "/v1/public/characters",
}
export default withRouter(withStyles(styles)(SearchMarvel));
