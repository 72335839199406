import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import SearchMarvel from './Search';

const styles = theme => ({
  stryker: {
    textAlign: 'center',
  },
  strykerLogo: {
    height: '15vmin',
    filter: 'invert(1)',
    margin: '3vh',
  },
  strykerHeader: {
    backgroundColor: '#282c34',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(10px + 2vmin)',
    color: '#ffffff',
  },
});

class View extends React.Component {

  render() {
    const { classes } = this.props;

    return (
      <section className={classes.strykerHeader}>
        {/* <img src={logo} className={classes.strykerLogo} alt="logo" /> */}
        <SearchMarvel />
      </section>
    );
  }
}

export default withStyles(styles)(View);
