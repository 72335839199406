// import Sudoku from './Sudoku'

// export default Sudoku

import React from 'react';

import { withStyles } from '@material-ui/core/styles';

import Sudoku from './Sudoku';

const styles = theme => ({
  stryker: {
    textAlign: 'center',
  },
  strykerContainer: {
    backgroundColor: '#282c34',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 'calc(10px + 2vmin)',
    color: '#ffffff',
  }
});

class View extends React.Component {

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.strykerContainer}>
        {/* <h1>Play Time!</h1> */}
        <Sudoku />
      </div>
    );
  }
}

export default withStyles(styles)(View);
